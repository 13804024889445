<template>
  <div class="position-relative banner-partnero-container">
    <div class="bg-partnero position-absolute h-100">
      <div class="btn-join-partnero">
        <div class="content-background">
          <h2 class="title-boost-your">
            {{ $t('referral.titleBoostYourIncome') }}
          </h2>
          <h1 class="title-ecomdy-affiliate">
            {{ $t('referral.titleEcomdyAffiliateProgram', {platformName: platformName}) }}
          </h1>
          <p class="txt-refer-clients">
            {{ $t('referral.txtReferClientsToEcomdy', {platformName: platformName}) }}
          </p>
          <p class="txt-refer-clients">
            {{ $t('referral.txtCompetitiveCommissions') }}
          </p>
        </div>
        <div class="btn-controller">
          <btn-loading
            v-if="!(infoPartneroUser && infoPartneroUser.partner)"
            id="trigger-join-now"
            variant-convert="btn-submit"
            class="btn-login"
            pill
            :loading="loading"
            @click="handleJoinPartneroPrograms"
          >
            {{ $t('referral.tabAffiliateTerms.btnJoinNow') }}
          </btn-loading>
          <b-button
            class="btn-join-now"
            @click.prevent="btnLearnMore"
          >
            {{ $t('referral.btnLearnMore') }}
          </b-button>
        </div>
      </div>

      <join-partnero-success-modal
        ref="open-join-partnero-success-modal"
        :mail-joined="mailJoined"
      />
    </div>
    <div class="banner-partnero-image w-100 h-100">
      <img
        v-lazy="isEcomdyPlatform ? require('@/assets/images/pages/partnero/bg-page.png') : require('@/assets/images/pages/partnero/bg-page-whitelabel.png')"
        class="image w-100 h-100"
        alt="guide"
      >
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'
import _isEmpty from 'lodash/isEmpty'
import JoinPartneroSuccessModal from '@/views/referral-programs-new/components/joinPartneroSuccessModal.vue'
import { toastification } from '@core/mixins/toast'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('referralProgramsNew')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    JoinPartneroSuccessModal,
    BtnLoading,
    BButton,
  },

  mixins: [envMixin, toastification],

  data() {
    return {
      urlDocsPartnero: process.env.VUE_APP_GUIDE_TO_PARTNERO,
      infoPartneroUser: null,
      mailJoined: null,
    }
  },

  computed: {
    ...mapGetters(['status', 'loading', 'message', 'accountPartnero']),
    ...mapGettersAuth(['user']),
  },

  watch: {
    user: {
      handler(userInfo) {
        if (!_isEmpty(userInfo?.data?.partnero)) {
          this.infoPartneroUser = userInfo?.data?.partnero
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['joinPartneroPrograms']),
    ...mapActionsAuth(['getAccountInfo']),

    btnLearnMore() {
      window.open(this.urlDocsPartnero)
    },

    async handleJoinPartneroPrograms() {
      await this.joinPartneroPrograms()
      if (this.status) {
        this.mailJoined = this.accountPartnero?.poEmail
        await this.getAccountInfo()
        this.$refs['open-join-partnero-success-modal'].showModal()
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.banner-partnero-container {
  height: 270px;
  width: 100%;

  .bg-partnero {
    display: flex;
    align-items: center;
    padding-left: 48px;

    .btn-join-partnero {

      .content-background {
        color: #fff;

        .title-boost-your {
          color: #fff;

          @media (max-width: 1440px) {
            font-size: 23px
          }

          @media (max-width: 390px) {
            font-size: 18px;
          }
        }

        .title-ecomdy-affiliate {
          color: #fff;

          @media (max-width: 1440px) {
            font-size: 26px
          }

          @media (max-width: 390px) {
            font-size: 23px;
          }
        }

        .txt-refer-clients {
          font-size: 20px;
          margin: 0;

          @media (max-width: 1440px) {
            font-size: 18px
          }

          @media (max-width: 390px) {
            font-size: 15px;
          }
        }
      }

      .btn-controller {
        margin-top: 2rem;
        display: flex;
        align-items: center;

        .btn-join-now {
          padding: 12px 24px;
          border-radius: var(--btn-border-radius-base);
          font-size: 14px;
          border: 1px solid #FFF !important;
          background: transparent !important;
        }

        .btn-login {
          margin: 0 1rem 0 0;
          padding: 12px 24px;
          height: 42px;
          //width: 122px;

          @media (max-width: 575px) {
            margin: 0 0 1rem 0;
          }
        }

        @media (max-width: 575px) {
          flex-direction: column;
          align-items: start;
        }

        @media (max-width: 390px) {
          margin-top: 1rem;
        }
      }
    }

    @media (max-width: 575px) {
      padding-left: 30px;
    }
  }

  .banner-partnero-image {

    .image {
      border-radius: var(--border-radius-2xl);
      object-fit: cover;
    }
  }
}
</style>
